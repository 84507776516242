//Colors
$arv-yellow: rgb(255, 225, 183);
$arv-black: rgb(26, 26, 26);
$arv-blue: rgb(57, 19, 76);

//Spacing
$extrasmall: 8px;
$small: 16px;
$medium: 24px;
$large: 32px;
$extralarge: 80px;

body {
	font-size: 1.2rem;
}

h1 {
	margin-top: $small;
	margin-bottom: $medium;
	color: $arv-blue;
	font-size: 60px;
	font-weight: 500;
	text-align: center;
}

h2 {
	margin-top: $small;
	margin-bottom: $medium;
	color: $arv-blue;
	font-size: 30px;
	font-weight: 500;
	text-align: center;
}

//Info-header
.info-header-container {
	background-color: $arv-yellow !important;
	padding: 10.5px 0 10.5px 0;

	.text-line-header {
		padding-right: 15px;

		&.text-line-header-right {
			text-align: right;
		}

		a {
			color: $arv-blue;
			font-size: 16px;
			line-height: 20px;
			text-size-adjust: 100%;
			text-decoration: none;
		}

		a:hover {
			text-decoration: none;
		}
	}
}

//Header
.header-container {
	background-color: white;

	.header {
		display: flex;
		justify-content: center;
		padding: $medium 0px;

		.img-header {
			width: 80px;
		}
	}
}

.link-bar {
	margin-bottom: $small;
	a {
		color: $arv-black;

		i {
			margin-right: 5px;
		}
	}

	a:hover {
		color: $arv-blue;
	}

	.icon {
		width: 10px;
	}
}

//Organizationlist and cards
.cards-container {
	margin-top: $small;
	margin-bottom: $medium;
	min-height: 100vh;

	.col-card {
		display: flex;
		margin-bottom: $small;
		padding: 0 8px 0 8px;

		.card-link {
			padding: $medium;
			border: solid 3px #39134c;
			border-radius: 0px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-content: space-between;
			color: $arv-black;
			text-decoration: none;

			&:focus {
				box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.8),
					0 5px 20px -5px rgba(0, 0, 0, 0.5);
			}

			.card-arv {
				border: 0px;
				width: 100%;

				.card-img-wrapper {
					.card-img-top {
						width: 100%;
						height: 240px;
						object-fit: contain;
					}
				}

				.card-body {
					margin-top: $small;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
				}
			}
		}

		.card-link:hover {
			background-color: $arv-yellow;

			.card-arv {
				background-color: $arv-yellow;
			}
		}

		.card-link:focus {
			border-color: $arv-blue;
		}
	}
}

.org-container {
	min-height: 100vh;
}

.loading-container {
	min-height: 100vh;
}

.category-container {
	margin-bottom: 30px;

	.category-tag {
		border: 2px #39134c solid;
		text-align: center;
		color: #39134c;
		padding: 10px;
	}
}

//For aligne ting center
.align-center {
	display: flex;
	justify-content: center;
}

//Organization detail page
.org-card {
	max-width: 360px;
	min-width: 360px;
	border-radius: 0px;
	border-color: $arv-blue;
	border-width: 2px;
	color: $arv-blue;

	.card-header {
		border-color: $arv-blue;
		background-color: transparent;
		border-width: 2px;
	}
}

.org-accord {
	margin-bottom: $small;
	text-align: center;
	width: 100%;

	.accordion-header {
		margin: 0;

		.accordion-button {
			background: none;
			color: unset;

			&:not(.collapsed) {
				&::after {
					background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
				}
			}
		}
	}
}

.org-text-header {
	text-align: center;
}

.org-img {
	min-height: 30vh;
	max-height: 30vh;
	max-width: 100%;
}

.org-info-div {
	margin-top: $large;
	display: flex;
	flex-direction: row;
	justify-content: center;

	.info-text {
		width: 550px;
		text-align: left;
	}
}

.org-test-button-div {
	display: flex;
	flex-direction: row;
	justify-content: center;

	.testament-button {
		font-family: "Space Mono", sans-serif;
		font-size: 15px;
		font-weight: 700;
		padding: 16px 22px;
		color: $arv-blue;
		background-color: transparent;
		margin-top: $medium;
		margin-bottom: $large;
		border: #39134c 2px solid;
	}
}

//Footer
.footer {
	margin-top: $medium;
	padding: $extralarge 0px;
	color: white;
	background-color: $arv-black;
	text-align: center;
	width: 100%;
	height: 420px;
	// position: absolute;
	// bottom: 0;
	// left: 0;
	font-size: 1rem;

	.footer-header {
		font-family: "Space Mono", sans-serif;
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 25px;
		color: white;
	}

	a {
		color: white;
		font-weight: 700;
		text-decoration: none;
	}

	a:hover {
		color: $arv-yellow;
	}

	p {
		line-height: 40px;
	}

	.footer-form {
		font-family: "Space Mono", sans-serif;
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		justify-content: center;

		form {
			.form-input {
				font-family: "Cabin", sans-serif;
				width: 380px;
				padding: 5px;
			}

			div {
				margin-top: $extrasmall;
				display: flex;
				justify-content: flex-end;

				.button {
					margin: 2px;
					font-family: "Space Mono", sans-serif;
					padding: 7px 12px;
					font-size: 15px;
					font-weight: 700;
					color: $arv-blue;
				}
			}
		}
	}
}

//Wrapper for parent info
.parent-container {
	margin-top: $medium;
	margin-bottom: $medium;
	color: $arv-blue;
}

//A div to make sure the content does not load beneath footer
.phantom-div {
	height: 420px;
}

@include media-breakpoint-down(md) {
	h1 {
		font-size: 32px;
	}

	h2 {
		font-size: 22px;
	}

	.org-card {
		// margin-top: $medium;
	}
}

@include media-breakpoint-down(sm) {
	.footer {
		padding: $extralarge 15px;

		p {
			line-height: 30px;
		}

		.footer-form {
			form {
				.form-input {
					width: 220px;
				}

				div {
					margin-top: $extrasmall;
					display: flex;
					justify-content: flex-end;

					.button {
						margin: 2px;
						font-family: "Space Mono", sans-serif;
						padding: 7px 12px;
						font-size: 15px;
						font-weight: 700;
						color: $arv-blue;
					}
				}
			}
		}
	}
}
